<template>
    <div class="commodity-detail">
        <app-header v-if="title" :title="title" :isShowBack="true"></app-header>
        <div>
            <el-form ref="myForm" :model="myForm" :rules="rules">
                <el-form-item label-width="150px" label="经销商" prop="supplierId">
                    <el-select v-model="myForm.supplierId" placeholder="请选择供应商" filterable disabled>
                        <el-option v-for="item in supplierList" :key="item.id" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label-width="150px" label="商品名称" prop="name">
                    <el-input v-model="myForm.name" maxlength="10" disabled></el-input>
                </el-form-item>
                <el-form-item label-width="150px" label="商品编码" prop="code">
                    <el-input v-model="myForm.code" maxlength="10" disabled></el-input>
                </el-form-item>
                <el-form-item label-width="150px" label="经销价（元）" prop="supplyPrice">
                    <el-input v-model="myForm.supplyPrice" maxlength="10" disabled></el-input>
                </el-form-item>
                <el-form-item label-width="150px" label="售价范围" prop="dealerPriceVal">
                    <el-input v-model="myForm.dealerPriceVal" maxlength="10" disabled></el-input>
                </el-form-item>
                <el-form-item label-width="150px" label="销售价（元/件）" prop="sellPrice">
                    <el-input v-model="myForm.sellPrice" maxlength="10" :disabled="notEdit"></el-input>
                </el-form-item>
                <div class="submitBox" v-show="!notEdit">
                  <el-button style="width: 180px" type="primary" @click="submit()">提交</el-button>
              </div>
            </el-form>
        </div>
        <el-dialog :visible.sync="dialogVisible" width="50%">
            <img width="100%" :src="dialogImageUrl" alt />
        </el-dialog>
    </div>
  </template>
  <script>
    let priceVali = (rule, value, callback) => {
      var reg = /(^[0-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{1,2}$)/
      if (!reg.test(value)) {
        callback(
          new Error("请输入数字，且小数点后最多保留2位")
        );
      } else {
        callback();
      }
    };
    import cabinetUtil from "@utils/cabinetUtil.js"
    export default {
      data() {
        let _this = this;
        return {
            title: '编辑商品',
            notEdit: false,
            supplierList: [],
            typeList: [],
            lowPrice: '',
            hightPrice: '',
            dialogVisible: false,
            dialogImageUrl: '',
            myForm: {
                supplierId: '',
                name: '',
                code: '',
                supplyPrice: '',
                dealerPriceVal: '',
                sellPrice: '',
            },
            rules: {
                sellPrice: [{
                  required: true,
                  trigger: "change",
                  validator: priceVali,
                }]
            }
        };
      },
      activated() {
        console.log("commodity-detail activated!!");
        this.title = ["发布商品", "编辑商品", "商品详情"][{
          add: 0,
          edit: 1,
          show: 2
        } [this.$route.params.type]];
        this.supplierList = []
        this.$nextTick(() => {
            if (this.$refs["myForm"]) {
                this.$refs["myForm"].resetFields();
            }
        })
        this.myForm =  {
            supplierId: '',
            name: '',
            code: '',
            supplyPrice: '',
            dealerPriceVal: '',
            sellPrice: '',
        }
        this.getCategory();
        this.getMerchantList();
        if (this.$route.params.type == "edit" || this.$route.params.type == "show") {
            this.getDetail();
        }
        if (this.$route.params.type == "show") {
          this.notEdit = true
        } else {
          this.notEdit = false
        }
      },
      methods: {
        submit() {
          this.$refs["myForm"].validate((valid) => {
            if(valid){
              if(this.lowPrice && this.hightPrice){
                if(Number(this.myForm.sellPrice) < this.lowPrice || Number(this.myForm.sellPrice) > this.hightPrice){
                  return this.$message({
                      message: '销售价不能超出售价范围',
                      type: 'warning'
                  })
                }
              }else{
                  if(Number(this.myForm.sellPrice) < Number(this.myForm.supplyPrice)) {
                    return this.$message({
                        message: '销售价不能低于经销价',
                        type: 'warning'
                    })
                  }
                }
              let dto = {
                sellPrice: this.myForm.sellPrice,
                lowPrice: this.lowPrice,
                hightPrice: this.hightPrice,
                id: this.$route.params.id
              }
              let message = "编辑商品成功";
              this.post("mall-service/items/update", dto,{isUseResponse: true}).then(res => {
                  if(res.data.code == 0) {
                    this.$message({
                        showClose: true,
                        message: message,
                        type: "success"
                    });
                    this.$back();
                  }
              })
            }
          })
        },
        getDetail() {
          this.get("mall-service/items/detail/" + this.$route.params.id).then(res => {
            this.myForm.supplierId = res.supplierId;
            this.myForm.name = res.name;
            this.myForm.code = res.code;
            this.myForm.supplyPrice = res.supplyPrice;
            res.lowPrice = res.lowPrice ? Number(res.lowPrice).toFixed(2) : '',
            res.hightPrice = res.hightPrice ? Number(res.hightPrice).toFixed(2) : '',
            this.myForm.dealerPriceVal = res.lowPrice + '-' + res.hightPrice;
            this.myForm.sellPrice = res.sellPrice;
            this.lowPrice = res.lowPrice;
            this.hightPrice = res.hightPrice;
          })
        },
        getCategory() {
          let dto = {
            pageNum: 1,
            pageSize: 9999,
            isDisable: 1,
            state: 1,//默认传1
          };
          this.post("mall-service/category/page/query", dto, {
            isUseResponse: true
          }).then(res => {
            var array = res.data.list;
            array.forEach(item => {
              item.value = item.id;
              item.label = item.name;
            })
            this.typeList = array;  
          })
        },
        getMerchantList() {
          cabinetUtil.getMerchantList({roleType: 3}).then(res => {
            this.supplierList = res
          })
        },
      }
    };
  </script>
  <style lang="scss">
    .commodity-detail {
      .el-form {
        margin: 0 auto;
        max-width: 1100px;
      }
    }
    .submitBox {
        text-align: center;
        padding-bottom: 20px;
    }
  </style>