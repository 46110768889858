var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "commodity-detail" },
    [
      _vm.title
        ? _c("app-header", { attrs: { title: _vm.title, isShowBack: true } })
        : _vm._e(),
      _c(
        "div",
        [
          _c(
            "el-form",
            { ref: "myForm", attrs: { model: _vm.myForm, rules: _vm.rules } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "150px",
                    label: "经销商",
                    prop: "supplierId"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择供应商",
                        filterable: "",
                        disabled: ""
                      },
                      model: {
                        value: _vm.myForm.supplierId,
                        callback: function($$v) {
                          _vm.$set(_vm.myForm, "supplierId", $$v)
                        },
                        expression: "myForm.supplierId"
                      }
                    },
                    _vm._l(_vm.supplierList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "150px",
                    label: "商品名称",
                    prop: "name"
                  }
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "10", disabled: "" },
                    model: {
                      value: _vm.myForm.name,
                      callback: function($$v) {
                        _vm.$set(_vm.myForm, "name", $$v)
                      },
                      expression: "myForm.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "150px",
                    label: "商品编码",
                    prop: "code"
                  }
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "10", disabled: "" },
                    model: {
                      value: _vm.myForm.code,
                      callback: function($$v) {
                        _vm.$set(_vm.myForm, "code", $$v)
                      },
                      expression: "myForm.code"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "150px",
                    label: "经销价（元）",
                    prop: "supplyPrice"
                  }
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "10", disabled: "" },
                    model: {
                      value: _vm.myForm.supplyPrice,
                      callback: function($$v) {
                        _vm.$set(_vm.myForm, "supplyPrice", $$v)
                      },
                      expression: "myForm.supplyPrice"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "150px",
                    label: "售价范围",
                    prop: "dealerPriceVal"
                  }
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "10", disabled: "" },
                    model: {
                      value: _vm.myForm.dealerPriceVal,
                      callback: function($$v) {
                        _vm.$set(_vm.myForm, "dealerPriceVal", $$v)
                      },
                      expression: "myForm.dealerPriceVal"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "150px",
                    label: "销售价（元/件）",
                    prop: "sellPrice"
                  }
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "10", disabled: _vm.notEdit },
                    model: {
                      value: _vm.myForm.sellPrice,
                      callback: function($$v) {
                        _vm.$set(_vm.myForm, "sellPrice", $$v)
                      },
                      expression: "myForm.sellPrice"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.notEdit,
                      expression: "!notEdit"
                    }
                  ],
                  staticClass: "submitBox"
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "180px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.submit()
                        }
                      }
                    },
                    [_vm._v("提交")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, width: "50%" },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }